:root {
  --navbar-height: 7.2rem;
  --navbar-padding: 1.6rem;
}

.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid var(--color-grey-90);
  background-color: var(--color-white);
}

.navbar-menu {
  max-width: 1440px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;

  @media screen and (min-width: 768px) {
    padding: 21px 100px;
  }
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-right {
  display: flex;
  align-items: center;
  gap: 2rem;
}

/* logo */
.navbar .logo {
  width: 138px;
  height: 23px;
  background: no-repeat url('../icons/wordmark.svg');
  background-size: cover;
  text-indent: -9999px;
  flex-shrink: 0;
  padding: 0;
  margin-right: 16px;
}

.navbar-institution {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  color: #40374f;

  @media screen and (min-width: '768px') {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
  }
}

.navbar-login {
  color: var(--color-purple-heart);
  font-size: 1.5rem;
  font-weight: 600;
  background-color: transparent !important;
}

.navbar-logout {
  color: var(--color-purple-heart);
  font-size: 1.5rem;
  font-weight: 600;
  background-color: transparent !important;

  &:hover {
    color: var(--color-purple-heart) !important;
  }
}
