:root {
  /* sizes  */
  --icon-size-x-small: 8px;
  --icon-size-small: 16px;
  --icon-size-regular: 20px;
  --icon-size-medium: 24px;
  --icon-size-large: 32px;
  --icon-size-x-large: 50px;
  --icon-size-xx-large: 120px;
}

@define-mixin icon {
  display: inline-block;
  content: '';
  width: var(--icon-size-small);
  height: var(--icon-size-small);
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  &.is-block {
    display: block;
  }
  &.is-x-small {
    width: var(--icon-size-x-small);
    height: var(--icon-size-x-small);
  }
  &.is-regular {
    width: var(--icon-size-regular);
    height: var(--icon-size-regular);
  }
  &.is-medium {
    width: var(--icon-size-medium);
    height: var(--icon-size-medium);
  }
  &.is-large {
    width: var(--icon-size-large);
    height: var(--icon-size-large);
  }
  &.is-x-large {
    width: var(--icon-size-x-large);
    height: var(--icon-size-x-large);
  }
  &.is-xx-large {
    width: var(--icon-size-xx-large);
    height: var(--icon-size-xx-large);
  }
  &.ic-teladoc {
    background-image: url('../icons/teladoc.svg');
  }
  &.ic-radio-check-primary {
    background-image: url('../icons/ic_radio_check_primary.svg');
  }
  &.ic-check-circle-green {
    background-image: url('../icons/ic_check_circle_green.svg');
  }
  &.ic-circle-grey {
    background-image: url('../icons/ic_circle_grey.svg');
  }
  &.ic-email {
    background-image: url('../icons/email.svg');
  }
  &.ic-profile-add {
    background-image: url('../icons/ic-profile-add.svg');
    &-mint {
      background-image: url('../icons/ic-profile-add-mint.svg');
    }
  }
  ,
  &.ic-add {
    background-image: url('../icons/ic_add.svg');
    &-mint-circle-purple {
      background-image: url('../icons/ic_add-mint-circle-purple.svg');
    }
  }
  ,
  &.ic-search {
    background-image: url('../icons/ic_search_black_24px.svg');
    &-grey {
      background-image: url('../icons/ic_search_grey.svg');
    }
    &-primary {
      background-image: url('../icons/ic_search_primary.svg');
    }
  }
  ,
  &.ic-arrow-forward-green {
    background-image: url('../icons/ic_arrow_forward_green.svg');
  }
  &.ic-caret-down {
    background-image: url('../icons/ic_caret_down.svg');
  }
  &.ic-signature {
    background-image: url('../icons/signature.svg');
  }
  &.ic-request-submitted {
    background-image: url('../icons/request-submitted.svg');
  }
  &.ic-checkbox {
    background-image: url('../icons/ic_checkbox.svg');
  }
  &.ic-dothealth {
    background-image: url('../icons/dothealth.svg');
  }
  &.ic-green-check{
    background-image: url('../icons/ic-green-check.svg');
  }
  &.ic-warning {
    &-circle-red {
      background-image: url('../icons/ic-warning-circle-red.svg');
    }
  }
  ,
  &.ic-file {
    &-purple {
      background-image: url('../icons/ic-file-purple.svg');
    }
  }
  ,
  &.ic-close {
    &-grey {
      background-image: url('../icons/ic-close-grey.svg');
    }
  }
  @mixin-content;
}

.icon {
  @mixin icon;
}

.for-title {
  margin-bottom: 16px;
}

.text-with-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  .icon {
    margin-right: 8px;
  }
  .text {
    padding-bottom: 6px;
  }
  &.is-centered {
    justify-content: center;
  }
}
